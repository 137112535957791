<template>
  <section id="message" class="padding-y-150">
    <h2>
      Message from <br class="sp-only" />
      a Representative <br class="sp-only tablet-only" />
      at R.C.I.
    </h2>
    <p class="description">代表からのメッセージ</p>
    <div class="wrap">
      <div class="ceo-img">
        <img src="@/assets/RCI_top_daihyo.webp" alt="代表取締役 川上貴史" />
      </div>
      <div class="message">
        <p>
          好きなことを仕事に。私は幼い頃から車が好きで、自動車に関わる仕事がしたいとずっと思ってきました。
          そして、今、株式会社R.C.I.を立ち上げ、自動車陸送の事業をしています。この仕事を通して、お客様と出会い、お客様にご満足していただきたい。そんな思いから、業界最安値でサービスを提供できるように尽力させていただきます。
          もちろん、コストだけでなく、安心・安全と丁寧な対応もお約束させていただきます。
        </p>
        <p class="description">代表取締役 <span>川上 貴史</span></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MessageVue",
};
</script>

<style lang="scss" scoped>
section {
  background-color: var(--black);
  h2 {
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      line-height: 2.5rem;
    }
  }
  .description {
    color: var(--white);
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1100px) {
      display: block;
      padding: 0;
    }
    .ceo-img {
      width: 45%;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 70%;
        margin: 0 auto;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0;
      }
    }
    .message {
      width: 50%;
      @media screen and (max-width: 1100px) {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        margin-top: 20px;
      }
      p {
        color: var(--white);
        line-height: 2rem;
      }
      .description {
        text-align: start;
        margin-bottom: 0;
        span {
          margin: 0 5px;
        }
      }
    }
  }
}
</style>
