<template>
  <section id="method">
    <h2>Transportation Method</h2>
    <p class="description">輸送方法</p>
    <div class="small-content">
      <div class="method">
        <img src="@/assets/RCI_top_check.webp" />
        <h3>自走陸送</h3>
      </div>
      <div class="method">
        <img src="@/assets/RCI_top_check.webp" />
        <h3>キャリアカー/積載車</h3>
      </div>
      <div class="method">
        <img src="@/assets/RCI_top_check.webp" />
        <h3>カーフェリー</h3>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TransportationMethod",
};
</script>

<style lang="scss" scoped></style>
