<template>
  <TopVue />
  <AboutUs />
  <OurService />
  <TransportationMethod />
  <VehiclesWeCanHandle />
  <ReasonsToChooseUs />
  <Price />
  <Message />
  <Information />
  <Flow />
  <!-- <Faq /> -->
  <Contact />
</template>

<script>
// @ is an alias to /src
import TopVue from "@/components/Home/Top.vue";
import AboutUs from "@/components/Home/AboutUs.vue";
import OurService from "@/components/Home/OurService.vue";
import TransportationMethod from "@/components/Home/TransportationMethod.vue";
import VehiclesWeCanHandle from "@/components/Home/VehiclesWeCanHandle.vue";
import ReasonsToChooseUs from "@/components/Home/ReasonsToChooseUs.vue";
import Price from "@/components/Home/Price.vue";
import Message from "@/components/Home/Message.vue";
import Information from "@/components/Home/Information.vue";
import Flow from "@/components/Home/Flow.vue";
// import Faq from "@/components/Home/Faq.vue";
import Contact from "@/components/Home/Contact.vue";

export default {
  name: "HomeView",
  components: {
    TopVue,
    AboutUs,
    OurService,
    TransportationMethod,
    VehiclesWeCanHandle,
    ReasonsToChooseUs,
    Price,
    Message,
    Information,
    Flow,
    // Faq,
    Contact,
  },
};
</script>
