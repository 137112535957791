<template>
  <section id="reasons">
    <h2>Reasons to choose us</h2>
    <p class="description">R.C.I.が選ばれる理由</p>
    <div class="wrap">
      <div class="reasons">
        <div class="reason">
          <h3>価格で他社に負けません</h3>
          <p>
            一般貨物事業者で取得した見積書をいただければ、その価格をくぐります。相見積もりで負けることはありません。価格では絶対に負けませんし、丁寧な対応をさせていただきます。
          </p>
        </div>
        <div class="reason">
          <h3>徹底した安全管理</h3>
          <p>
            運搬前には車両を細かく確認、20項目を超える評価ポイントを設けて安全管理を徹底しています。実践だけでなく、座学やロープレなどの厳しい自社研修をクリアした専門のプロドライバーによる運搬をします。
          </p>
        </div>
      </div>
      <div class="reasons">
        <div class="reason">
          <h3>全国対応</h3>
          <p>日本の中心である岐阜県を拠点にして、全国対応しています。</p>
        </div>
        <div class="reason">
          <h3>コストを抑えた輸送</h3>
          <p>
            トレーラーを数台所有しているため、一度にたくさんの輸送が可能です。大型トレーラーにて複数台同時に運ぶことでコストを抑えることができます。
          </p>
        </div>
      </div>
      <div class="reasons">
        <div class="reason">
          <h3>多種多様な車両に対応</h3>
          <p>
            大型車、産業機械や重建機といった特殊車両など、多種多様な車両の輸送に対応しています。軽自動車から大型車両、スーパーカーまで、輸送はR.C.I.にお任せください。輸送の難しい事故現状車の輸送にも対応しています。
          </p>
        </div>
        <div class="reason-img">
          <img
            src="@/assets/RCI_top_004.webp"
            alt="R.C.Iが選ばれる理由 イメージ（PC）"
            class="pc-only"
          />
          <img
            src="@/assets/RCI_top_004sp.webp"
            alt="R.C.Iが選ばれる理由 イメージ（PC）"
            class="sp-only tablet-only"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ReasonsToChooseUs",
};
</script>

<style lang="scss" scoped>
section {
  .wrap {
    .reasons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .reason {
        width: 49%;
        height: 220px;
        padding: 30px 40px;
        margin: 10px 0;
        background-color: var(--sub1);
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          height: 250px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          height: auto;
          padding: 20px 10px;
        }
        h3 {
          margin-bottom: 15px;
          @media screen and (max-width: 767px) {
            line-height: 1.5rem;
          }
        }
      }
      .reason-img {
        width: 49%;
        height: 220px;
        margin: 10px 0;
        background-color: var(--black);
        overflow: hidden;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          height: 250px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
