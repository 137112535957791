<template>
  <Header />
  <router-view />
  <img
    src="@/assets/RCI_top_modoru.webp"
    alt="TOPへ戻る"
    class="top-scroll"
    v-if="showScrollButton"
    v-scroll-to="'#app'"
  />
  <Footer />
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showScrollButton: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // コンポーネントが破棄される前にイベントリスナーを削除
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // ページのスクロール位置を取得
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // スクロール位置が一定値以上の場合にスクロールボタンを表示
      this.showScrollButton = scrollTop > 200;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

/*---------------------
 共通初期設定
---------------------*/
:root {
  --main: #a69d4e;
  --sub1: #e9e9e9;
  --sub2: #737373;
  --font: #4c4948;
  --cover: #d7e1e628;
  --black: #000000;
  --white: #ffffff;
  --gray: #bcbcbc;
  --small-space: 100px;
  --small-space-sp: 50px;
  --space: 120px;
  --space-sp: 60px;
  --big-space: 200px;
  --big-space-sp: 100px;
}

#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  color: var(--font);
  line-height: 1.5em;
  overflow-x: hidden;
  @media screen and (max-width: 767px) {
    line-height: 1.6em;
    margin-top: 0;
  }
}

html * {
  box-sizing: border-box !important;
}

body {
  margin: 0;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

/*---------------------
 display 設定
---------------------*/
.pc-only {
  @media screen and (max-width: 1100px) {
    display: none;
  }
}
.pc-tablet-only {
  display: none;
  @media screen and (min-width: 768px) {
    display: initial !important;
  }
}
.tablet-only {
  display: none;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    display: initial !important;
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}

/*---------------------
 Heading, Paragraph
---------------------*/
h1,
h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
  color: var(--main);
  margin: 0;
  @media screen and (max-width: 767px) {
    font-size: 30px;
    font-weight: 500;
    line-height: 2rem;
  }
}
h3 {
  font-size: 23px;
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
  color: var(--main);
  margin: 0;
  @media screen and (max-width: 767px) {
    font-size: 20px;
  }
}
h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
  color: var(--white);
  margin: 0;
  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
}
.form-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 1rem;
  color: var(--font);
  margin: 0;
  @media screen and (max-width: 767px) {
    font-size: 30px;
    font-weight: 500;
    line-height: 2em;
  }
}
.description {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: center;
  color: var(--font);
  margin-top: 20px;
  margin-bottom: 40px;
  @media screen and (max-width: 767px) {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--font);
  margin: 0;
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}
/*---------------------
 Section
---------------------*/
section {
  padding: 200px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 70px 0;
  }
  .wrap {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    @media screen and (max-width: 1100px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .content {
    width: 800px;
    margin: 0 auto;
    @media screen and (max-width: 1100px) {
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      margin: 0;
    }
  }
  .small-content {
    width: 550px;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      margin: 0;
    }
    .method {
      background-color: var(--sub1);
      display: flex;
      align-items: center;
      padding: 10px 5%;
      margin: 20px 0;
      h3 {
        color: var(--font);
      }
      img {
        width: 50px;
        margin-right: 5px;
      }
    }
  }
  .img-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: block;
      margin-bottom: 20px;
    }
    .img-card {
      width: 45%;
      margin: 0;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }
}

/*---------------------
 共通スペース
---------------------*/
.padding-y-150 {
  padding: 150px 0;
  @media screen and (max-width: 1100px) {
    padding: 70px 0;
  }
}
/*---------------------
 TOPへスクロールボタン
---------------------*/
.top-scroll {
  width: 70px;
  position: fixed;
  bottom: 1px;
  right: 1px;
  z-index: 3;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    width: 55px;
    bottom: 60px;
  }
}
</style>
