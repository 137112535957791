<template>
  <div class="top">
    <video ref="player" autoplay muted playsinline loop>
      <source src="/video/RCI_top_001.mp4" />
      このブラウザではサポートされていません。
    </video>
    <div class="top-logo">
      <img
        src="@/assets/RCI_top_logo002.webp"
        alt="R.C.I. ROBECITY INTERNATIONAL"
      />
    </div>
    <div class="top-message">
      <div class="message-img">
        <img
          src="@/assets/RCI_top_catch001.webp"
          alt="全国対応 安心・安全 多種多様な車両に対応"
          class="pc-only"
        />
        <img
          src="@/assets/RCI_top_catch001sp.webp"
          alt="全国対応 安心・安全 多種多様な車両に対応"
          class="sp-only tablet-only"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopVue",
};
</script>

<style lang="scss" scoped>
.top {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    z-index: 0;
    @media screen and (max-width: 1100px) {
      height: 100vh;
    }
  }
  .top-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: var(--cover);
    z-index: 2;
    img {
      display: block;
      width: 40%;
      margin: 0 auto;
      @media screen and (min-width: 767px) and (max-width: 1100px) {
        width: 60%;
      }
      @media screen and (max-width: 767px) {
        width: 80%;
      }
    }
  }
  .top-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 5;
    .message-img {
      position: relative;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      img {
        position: absolute;
        bottom: 5vh;
        width: 60%;
        margin: auto;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 90%;
          bottom: 5vh;
        }
        @media screen and (max-width: 767px) {
          width: 90%;
          bottom: 13vh;
        }
      }
    }
  }
}
</style>
