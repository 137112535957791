<template>
  <div class="footer">
    <div
      v-if="showFooterScrollButton"
      v-show="this.$route.path === '/'"
      class="sp-contact"
    >
      <div class="img-button">
        <img src="@/assets/RCI_top_m001sp.webp" v-scroll-to="'#phone'" />
      </div>
      <div class="img-button">
        <img src="@/assets/RCI_top_m002sp.webp" v-scroll-to="'#contact'" />
      </div>
    </div>
    <div class="footer-content">
      <img
        src="@/assets/RCI_top_logo002.webp"
        alt="株式会社R.C.I."
        class="footer-img"
      />
      <p class="copyright">Copyright © R.C.I. All Rights Reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutFooter",
  data() {
    return {
      showFooterScrollButton: false,
    };
  },
  mounted() {
    // スクロールイベントを監視
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // コンポーネントが破棄される前にイベントリスナーを削除
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // ページのスクロール位置を取得
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // スクロール位置が一定値以上の場合にスクロールボタンを表示
      this.showFooterScrollButton = scrollTop > 200;

      // 特定の要素までスクロールしたかどうかチェック
      const specialElement = document.getElementById("contact"); // 特定の要素のIDを指定する
      if (specialElement) {
        const bounding = specialElement.getBoundingClientRect();
        // 特定の要素の上端が画面内に入った場合、ボタンを非表示
        if (bounding.top >= window.innerHeight) {
          // no action
        } else {
          this.showFooterScrollButton = false;
        }
      } else {
        // no action
      }
    },
  },
};
</script>

<style lang="scss">
.sp-contact {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 60px;
  z-index: 5;
  display: none;
  .img-button {
    display: block;
    width: 50vw;
    height: 100%;
    padding: 1% 0;
    background-color: var(--black);
    border: 1px solid var(--white);
    img {
      display: block;
      width: 70%;
      margin: auto;
    }
  }
  @media screen and (max-width: 767px) {
    display: flex;
  }
}
.footer {
  height: 170px;
  background-color: var(--black);
  @media screen and (max-width: 767px) {
    height: 200px;
  }
  .footer-content {
    position: relative;
    margin-right: 100px;
    margin-left: 2%;
    padding-top: 75px;
    @media screen and (max-width: 767px) {
      position: unset;
      margin: 0 auto;
      padding: 30px 0;
    }
    .footer-img {
      width: 300px;
      @media screen and (max-width: 767px) {
        display: block;
        width: 50%;
        margin: 30px auto;
      }
    }
    .copyright {
      color: var(--white);
      position: absolute;
      text-align: end;
      bottom: 0;
      right: 0;
      @media screen and (max-width: 1150px) {
        font-size: 12px;
      }
      @media screen and (max-width: 767px) {
        text-align: center;
        position: unset;
        margin: 0;
      }
    }
  }
}
</style>
