<template>
  <section id="flow" class="padding-y-150">
    <h2>Flow</h2>
    <p class="description">お問い合わせからご利用の流れ</p>
    <div class="wrap">
      <div class="flow-row">
        <div class="flow-step">
          <h3>STEP 1</h3>
        </div>
        <div class="flow">
          <h4>お問い合わせと無料お見積もり</h4>
          <p>
            お電話、または、当ホームページのお問い合わせフォームからご連絡をお願いします。ご依頼内容を確認して、お見積りをさせていただきます。お見積りは無料です。
          </p>
        </div>
      </div>
      <div class="flow-row">
        <div class="flow-step">
          <h3>STEP 2</h3>
        </div>
        <div class="flow">
          <h4>運搬日の調整</h4>
          <p>
            お見積りの金額にご了承をいただきましたら、運搬日の調整をいたします。
          </p>
        </div>
      </div>
      <div class="flow-row">
        <div class="flow-step">
          <h3>STEP 3</h3>
        </div>
        <div class="flow">
          <h4>ご注文と料金のお振込</h4>
          <p>当社指定の方法にて、ご注文とお振込みをお願いいたします。</p>
        </div>
      </div>
      <div class="flow-row">
        <div class="flow-step">
          <h3>STEP 4</h3>
        </div>
        <div class="flow">
          <h4>運搬の実行</h4>
          <p>調整した日程にて、運搬を実行いたします。</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FlowVue",
};
</script>

<style lang="scss" scoped>
section {
  .wrap {
    .flow-row {
      margin: 40px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: 1100px) {
        display: block;
        margin: 30px 0;
      }
      .flow-step {
        width: 20%;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 1100px) {
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }
        h3 {
          line-height: 50px;
          width: 100%;
          height: 50px;
          color: var(--white);
          background-color: var(--sub2);
          @media screen and (max-width: 1100px) {
            text-align: center;
          }
        }
      }
      .flow {
        width: 75%;
        @media screen and (max-width: 1100px) {
          width: 100%;
        }
        h4 {
          color: var(--font);
          font-size: 25px;
          text-align: start;
          margin-bottom: 15px;
          @media screen and (max-width: 1100px) {
            text-align: center;
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
