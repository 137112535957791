<template>
  <section id="service" class="padding-y-150">
    <h2>Our Service</h2>
    <p class="description">サービス</p>
    <div class="wrap">
      <div class="img-content">
        <div class="img-card">
          <img src="@/assets/RCI_top_002.webp" alt="" />
          <h3>中古車販売店様向け自動車陸送</h3>
          <p>
            中古車販売店様や一般貨物事業者様などをクライアントにした法人向けの陸送サービスを行っています。継続的な取引ができるパートナーとしてお力添えさせていただきます。
          </p>
        </div>
        <div class="img-card">
          <img src="@/assets/RCI_top_003.webp" alt="" />
          <h3>保険会社様向け自動車陸送</h3>
          <p>
            自動車の故障、バッテリー上がり、パンク時などの保険会社様向けの陸送サービスを行っています。
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurService",
};
</script>

<style lang="scss" scoped>
section {
  background-color: var(--black);
  .description {
    color: var(--white);
  }
  .wrap {
    .img-content {
      .img-card {
        h3,
        p {
          color: var(--white);
          margin-top: 20px;
          @media screen and (max-width: 767px) {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
