<template>
  <section id="price" class="padding-y-150">
    <h2>Price</h2>
    <p class="description">料金について</p>
    <div class="content">
      <p>
        ご依頼いただく内容ごとにお見積りをさせていていただきますので、まずはお気軽にご相談ください。お客様に極力お値打ち価格をご提供できるように尽力させていただきます。
        一般貨物事業者で取得した見積書をいただければ、その価格をくぐります。相見積もりで負けることはありません。
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "PriceVue",
};
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub1);
  .content {
    p {
      line-height: 2rem;
    }
  }
}
</style>
