<template>
  <section id="vehicles" class="padding-y-150">
    <h2>Vehicles we can handle</h2>
    <p class="description">陸送の対応車両</p>
    <div class="small-content">
      <div class="method">
        <img src="@/assets/RCI_top_check.webp" />
        <h3>各種トラック</h3>
      </div>
      <div class="method">
        <img src="@/assets/RCI_top_check.webp" />
        <h3>大型車(トラック・バス等)</h3>
      </div>
      <div class="method">
        <img src="@/assets/RCI_top_check.webp" />
        <h3>乗用車</h3>
      </div>
      <div class="method">
        <img src="@/assets/RCI_top_check.webp" />
        <h3>大型トレーラーセット</h3>
      </div>
      <div class="method">
        <img src="@/assets/RCI_top_check.webp" />
        <h3>大型特殊（クレーン車等）</h3>
      </div>
      <div class="method">
        <img src="@/assets/RCI_top_check.webp" />
        <h3>スーパーカー</h3>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "VehiclesWeCanHandle",
};
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub2);
  .description {
    color: var(--white);
  }
}
</style>
